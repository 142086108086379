*{
  margin: 0;
}
body {
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Main__wrapper {
  background-color: #f8f9fb;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.Main__header_title {
  display: flex;
  justify-content: center;
  margin-top:3%;
  font-size: 30px;
  font-weight: 500;
}
.Search__logout_div {
  font-size: 28px;
  font-weight: bold;
  text-decoration: underline;
  padding: 0 10%;
  cursor: pointer;
}
.Search__logout_div_cart {
  font-size: 28px;
  font-weight: bold;
  text-decoration: underline;
  display: flex;
  justify-content: space-between;
  padding: 0 10%;
  cursor: pointer;
}

.checkout_end_note {
  text-align: center;
  margin-top: 3%;
  font-weight: bold;
}